<template>
	<div>
		<!--banner-->
		<section class="privacy_banner marginTop_fixed">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="banner-terms">
							<h1>Terms of Service</h1>
							<p>Updated July 22, 2021</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--For mobile version-->
		<section class="servicesAccording mobileshow">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="accordion faqsc" role="tablist">
							<b-card
								no-body
								v-for="(policy, index) in items"
								:key="policy.index"
							>
								<b-card-header header-tag="header" role="tab">
									<b-button
										block
										v-b-toggle="'accordion' + index"
										variant="info"
									>
										{{ policy.title }}
									</b-button>
								</b-card-header>
								<b-collapse
									:id="'accordion' + index"
									visible
									accordion="my-accordion"
									role="tabpanel"
								>
									<b-card-body>
										<p class="card-text" v-html="policy.details"></p>
									</b-card-body>
								</b-collapse>
							</b-card>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--end-->
		<!--main privacy-->
		<section class="maincontent_priv laptopshow">
			<div class="container eleven80">
				<div class="row">
					<!--left sidebar-->
					<div class="col-md-4">
						<div class="leftscrollnav sticky-top">
							<ul v-scroll-spy-active v-scroll-spy-link>
								<!--loop title -->
								<li v-for="(item, index) in items" :key="index">
									<a>{{ item.title }}</a>
								</li>
								<!--end-->
							</ul>
						</div>
					</div>
					<!--end-->
					<!--main-->
					<div class="col-md-8">
						<div class="pcontent">
							<div v-scroll-spy="{ offset: 85 }">
								<!--section start-->
								<div v-for="(content, index) in items" :key="index">
									<h3>{{ content.title }}</h3>
									<span v-html="content.details"></span>
								</div>
								<!--section end-->
							</div>
						</div>
					</div>
					<!--end-->
				</div>
			</div>
		</section>
		<!--end-->
	</div>
</template>

<script>
	import MainPage from "@/layouts/MainPage.vue";
	import eng from "../locales/terms_of_service_en.json";
	import ita from "../locales/terms_of_service_it.json";
	export default {
		data() {
			return {
				eng: eng,
				ita: ita,
			};
		},
		computed: {
			items() {
				return this.$i18n.locale === "en" ? this.eng : this.ita;
			},
		},
		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		},
		//Meta info title + description + others meta
		metaInfo() {
			return {
				title: "SEO Suite",
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [
					{ name: "description", content: "SEO Suite" },
					{ property: "og:title", content: "SEO Suite" },
					{ property: "og:site_name", content: "SEO Suite" },
					{ property: "og:type", content: "website" },
					{ name: "robots", content: "index,follow" },
				],
			};
		},
		//end meta info
	};
</script>

<style lang="scss" scoped></style>
